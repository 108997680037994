<template>
<div class="page">
    <v-list :finished="finished" @onLoad="onLoad" :finishedText="finishedText" ref="list">
        <div class="item" v-for="(item,index) in listData" :key="index">
            <div>
                <img v-if="item.avatar==''" src="./img/defaultAvatar.png" class="avatar">
                <img v-else :src="item.avatar" class="avatar">
            </div>
            <div class="name">{{item.userName}}</div>
            <div class="type">签到积分</div>
            <div class="points">+{{item.pointCount}}积分</div>
            <img src="./img/moneyIcon.png" class="money">
            <div class="line"></div>
        </div>
    </v-list>
</div>
</template>

<script>
import {
    getTeamUserPointUrl
} from "./api.js";
export default {
    name: 'teamPoints',
    data() {
        return {
            finishedText: "没有更多了",
            finished: false,
            listData: [],
            orderId: '',
            associationId: '',
            requestData: {
                curPage: 1,
                pageSize: 20,
            },
        }
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.associationId = this.$route.query.associationId;
    },
    methods: {
        onLoad() {
      this.getList();
    },
        getList() {
            let params = {
                curPage: this.requestData.curPage++,
                pageSize: this.requestData.pageSize,
                orderId: this.orderId,
                associationId: this.associationId,
            };
            this.$axios
                .get(`${getTeamUserPointUrl}`, {
                    params
                })
                .then((res) => {
                    if (res.code === 200) {
                        if (res.data.pages <= res.data.current) {
                            this.finished = true;
                        } else {
                            this.$refs.list._data.loading = false;
                        }
                        this.finishedText = "没有更多了";
                        if (res.data.records.length === 0) {
                            this.finishedText = "";
                            return;
                        }
                        res.data.records.forEach((item) => {
                            this.listData.push(item);
                        });
                    } else {}
                });
        },
    }
}
</script>

<style lang="less" scoped>
.page {
    width: 100%;
    min-height: 100vh;
    background-color: #fff;

    .item {
        // background-color: pink;
        width: 100%;
        height: 132px;
        position: relative;

        .line {
            width: calc(100% - 32px);
            height: 1px;
            background: rgba(0, 0, 0, 0.06);
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .money {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            right: 32px;
            transform: translateY(-50%);
        }

        .points {
            font-size: 32px;
            font-weight: 600;
            color: #333333;
            position: absolute;
            top: 50%;
            right: 74px;
            transform: translateY(-50%);
        }

        .type {
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 72px;
            left: 142px;
        }

        .name {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            position: absolute;
            top: 28px;
            left: 142px;
        }

        .avatar {
            width: 80px;
            height: 80px;
            position: absolute;
            top: 50%;
            left: 32px;
            transform: translateY(-50%);
        }
    }
}
</style>
